body,
#root {
  margin: 0;
  font-family: 'MuseoSans-500', sans-serif;
  background-color: #dae6ec;
}

@media only screen and (min-width: 768px) {
	body, #root {
		min-height: 100vh;
	}
}

code {
  font-family: 'MuseoSans-500', sans-serif;
}

@font-face {
  font-family: 'MuseoSans-500';
  font-weight: 500;
  src: url('../assets/fonts/MuseoSans-500.otf') format('truetype');
}

@font-face {
  font-family: 'MuseoSans-700';
  font-weight: 700;
  src: url('../assets/fonts/MuseoSans-700.otf') format('truetype');
}

@font-face {
  font-family: 'MuseoSans-900';
  font-weight: 900;
  src: url('../assets/fonts/MuseoSans_900.otf') format('truetype');
}
@font-face {
  font-family: 'MuseoSans-300';
  font-weight: 300;
  src: url('../assets/fonts/MuseoSans-300.otf') format('truetype');
}

@font-face {
  font-family: 'MuseoSans-100';
  font-weight: 100;
  src: url('../assets/fonts/MuseoSans-100.otf') format('truetype');
}

svg[aria-label='XYChart'] {
  overflow: visible;
}

@font-face {
  font-family: 'PPFormula-NarrowLight';
  font-weight: 500;
  src: url('../assets/fonts/PPFormula-NarrowLight.otf') format('truetype');
}

@font-face {
  font-family: 'PPFormula-NarrowSemibold';
  font-weight: 600;
  src: url('../assets/fonts/PPFormula-NarrowSemibold.otf') format('truetype');
}